import React from "react";
import InputMask from "react-input-mask";

interface PhoneInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    name?: string;
    id?: string;
    disabled?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
    return (
        <InputMask
            mask="+1 (999) 999 - 9999"
            value={props.value}
            onChange={props.onChange}
            type="tel"
            required={props.required}
            name={props.name}
            id={props.id}
            disabled={props.disabled}
            className="mt-2"
            validate={(value) => {
                if (value.length < 14) {
                    return false;
                }
            }}
        />
    );
};

export default PhoneInput;
